<template>
  <div class="pt-3">
    <Loader v-if="!cert" />
    <div class="dashboard__container--body">
      <div class="dashboard__container--body--col">
        <div>
          <h4>Edit Cert:</h4>
          <div class="mb-3">
            <label for="title">Title</label>
            <input type="text" v-model="cert.title"></input>
          </div>
          <div class="mb-5">
            <label for="description">Description</label>
            <input type="text" v-model="cert.description"></input>
          </div>
          <div class="mb-5">
            <label for="url">Link to get (optional)</label>
            <input type="text" v-model="cert.url"></input>
          </div>
          <div class="mb-3">
            <button class="btn btn__primary" @click="updateCert()">
              Update Cert
              <transition name="fade">
                <span class="ml-2" v-if="performingRequest">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// import * as moment from 'moment'
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'
const fb = require('../../firebaseConfig.js')

export default {
  name: 'cert',
  data: () => ({
    performingRequest: false,
  }),
  created () {
    this.$store.dispatch("getCertFromId", this.$route.params.id)
  },
  computed: {
    ...mapState(['userProfile', 'currentUser', 'cert']),
  },
  components: {
    Loader,
  },
  methods: {
    // updateStatus(row) {
    //   fb.backgroundChecksCollection.doc(row.id).update(row)
    // },
    async updateCert() {
      this.performingRequest = true
      await fb.certsCollection.doc(this.cert.id).update(this.cert)
      this.performingRequest = false
    }
  },
  beforeDestroy() {
    this.$store.dispatch("clearCert")
  }
}
</script>